import {
  buildOrganizationDetailsForm,
  OrganizationDetailsFormState,
} from "../../../../components/OrganizationDetailsForm/model";
import {
  buildPaymentInfoForm,
  PaymentInfoFormState,
} from "../../../../components/PaymentInfoForm/model";
import { defaultOrganizationDetailsFormState } from "../../../../components/OrganizationDetailsForm/validation";
import { defaultPaymentInfoFormState } from "../../../../components/PaymentInfoForm/validation";
import { Hub, Organization } from "../../../../../api/organization";
import {
  buildExportAuthorizationForm,
  ExportAuthorizationFormState,
} from "app/components/ExportAuthorizationForm/model";
import { defaultExportAuthorizationFormState } from "app/components/ExportAuthorizationForm/validation";
import {
  VisitorFormState,
  buildVisitorForm,
} from "app/components/VisitorForm/model";
import { defaultVisitorFormState } from "app/components/VisitorForm/validation";

export interface OrganizationFormState {
  details: OrganizationDetailsFormState;
  payment: PaymentInfoFormState;
  exportAuthorization: ExportAuthorizationFormState;
  visitor: VisitorFormState;
}

export const defaultOrganizationFormState: OrganizationFormState = {
  details: defaultOrganizationDetailsFormState,
  payment: defaultPaymentInfoFormState,
  exportAuthorization: defaultExportAuthorizationFormState,
  visitor: defaultVisitorFormState,
};

export const buildOrganizationForm = (
  organization: Organization
): OrganizationFormState => {
  const organizationForm = buildOrganizationDetailsForm(organization);
  const paymentForm = buildPaymentInfoForm(organization);
  const exportAuthorizationForm = buildExportAuthorizationForm(organization);
  const visitorForm = buildVisitorForm(organization);
  return {
    details: organizationForm,
    payment: paymentForm,
    exportAuthorization: exportAuthorizationForm,
    visitor: visitorForm,
  };
};
