import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface UpdateHubCommentCommandDTO extends city.colisactiv.organization.model.features.UpdateHubCommentCommandDTO {}
export interface HubCommentUpdatedEvent extends city.colisactiv.organization.model.features.HubCommentUpdatedEvent {}

export const updateHubComment = (
  command: UpdateHubCommentCommandDTO
): Promise<HubCommentUpdatedEvent | null> => {
  return http.put(
    buildOrganizationApiUrl(`/organizations/${command.organizationId}/hub/${command.hubId}/updateComment`), 
    JSON.stringify(command),
  );
};
