import React, { useCallback, useContext, useState } from 'react'
import { FormControlLabel, makeStyles } from '@material-ui/core'
import { themeContext, Theme } from '@smartb/archetypes-ui-components'
import { Address, organizationClient } from 'web-admin/src/api/organization'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { AutoComplete } from 'components'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    root: {
      borderRadius: 20
    },
    container: {
      marginLeft: '0px',
      marginRight: '0px',
      '& .MuiTypography-root': {
        fontSize: '14px'
      },
      '& .MuiButtonBase-root:hover': {
        background: 'transparent'
      }
    },
    icon: {
      color: theme.primaryColor
    },
    iconSize: {
      width: '22px',
      height: '22px'
    }
  }))

interface AddressAutocompleteProps {
  disabled?: boolean
  onChange: (address: Address) => void
  value?: Address
  label?: string
  showFullAddress?: boolean
  className?: string
  style?: CSSProperties
}

export const AddressAutocomplete = (props: AddressAutocompleteProps) => {
  const {
    disabled = false,
    onChange,
    value = undefined,
    label = 'Adresse',
    showFullAddress = false,
    className,
    style
  } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()

  const handleAddressChange = useCallback(
    (address: Address | Address[]) => {
      const singleAddress = Array.isArray(address) ? address[0] : address
      onChange(singleAddress)
    },
    [onChange]
  )

  const [autocompleteText, setAutocompleteText] = useState(
    'Rechercher une adresse'
  )
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Address[]>(
    []
  )

  const handleSearchAddress = useCallback(async (search: string) => {
    if (search.trim().length === 0) {
      setAutoCompleteOptions([])
      setAutocompleteText('Rechercher une adresse')
      return
    }

    try {
      const addresses: Address[] = await organizationClient.query.fetchGeoApiAddress({address: search})
      setAutoCompleteOptions(addresses)
      if (addresses.length === 0) {
        setAutocompleteText('Aucune adresse trouvée')
      }
    } catch (err) {
      console.error(err)
      setAutoCompleteOptions([])
      setAutocompleteText('Une erreur est survenue, veuillez réessayer')
    }
  }, [organizationClient])

  const getAddressName = useCallback((address: Address) => {
    return `${address.houseNumber} ${address.street}, ${address.zipCode} ${address.city}`
  }, [])

  const getStreetName = useCallback((address: Address) => {
    return `${address.houseNumber} ${address.street}`
  }, [])

  return (
    <FormControlLabel
      value={value}
      className={`${classes.container} ${className}`}
      style={style}
      control={
        <AutoComplete<Address>
          id='address-select'
          onChangeSelectedElement={handleAddressChange}
          options={autoCompleteOptions}
          defaultValue={value}
          label={label}
          onSearch={handleSearchAddress}
          noOptionsText={autocompleteText}
          getOptionLabel={getAddressName}
          getSelectedOptionLabel={showFullAddress?getAddressName:getStreetName}
          placeholder='Rechercher une adresse'
          readonly={disabled}
          style={{width: '100%'}}
        />
      }
      label={''}
      labelPlacement='end'
    />
  )
}
