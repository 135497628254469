import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface UpdateHubActivityEndDateCommandDTO extends city.colisactiv.organization.model.features.UpdateHubActivityEndDateCommandDTO {}
export interface HubActivityEndDateUpdatedEvent extends city.colisactiv.organization.model.features.HubActivityEndDateUpdatedEvent {}

export const updateHubActivityEndDate = (
  command: UpdateHubActivityEndDateCommandDTO
): Promise<HubActivityEndDateUpdatedEvent | null> => {
  return http.put(
    buildOrganizationApiUrl(`/organizations/${command.organizationId}/hub/${command.hubId}/updateActivityEndDate`), 
    JSON.stringify(command),
  );
};
