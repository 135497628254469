import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Hub } from 'web-admin/src/api/organization'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import frLocale from 'date-fns/locale/fr'
import { TextareaAutosize, makeStyles } from '@material-ui/core'
import { DatePicker, PopUpConfirmation, theme } from "components";

const useStyles = makeStyles(() => ({
  popup: {
    "& .MuiDialog-paper": {
      maxWidth: "1000px",
    },
  },
  commentTextField: {
    width: "100%",
    minHeight: "30px",
    borderRadius: "4px",
    borderColor: "lightgrey",
    padding: "5px",
    fontSize: 18,
    fontFamily: '"Montserrat", Arial',
    display: "inline-block",
    verticalAlign: "middle",
    resize: "none",
    "&:focus": {
      outline: "ridge thin",
      outlineColor: theme.secondaryColor,
    },
  },
}));

export enum HubPopupField {
  DATE, COMMENT
}

export interface HubPopupState {
  hub?: Hub|undefined,
  field?: HubPopupField
}

interface HubPopupProps {
  hub?: Hub
  field?: HubPopupField
  onSubmit: (hub: Hub) => void
  onCancel: () => void
}

export const HubPopup = (props: HubPopupProps) => {
  const {hub, field, onSubmit, onCancel } = props
  const [comment, setComment] = useState<string>(hub?.comment ?? "");
  const [date, setDate] = useState<Date|undefined>(undefined);
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (hub !== undefined) setComment(hub?.comment)
  }, [hub, setComment]);

  return (
    <PopUpConfirmation 
      open={hub!=undefined}
      onConfirm={()=>onSubmit({...hub!!, comment: comment, activityEndDate: date?.getTime()})}
      onClose={onCancel}
    >
      {field === HubPopupField.COMMENT &&
      <>
        <h2>{t("app_organizations_hub_comment")}</h2>
        <TextareaAutosize
          title={t("app_organizations_hub_comment")}
          id="organization-hub-form-comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className={classes.commentTextField}
        />
      </>}
      {field === HubPopupField.DATE && <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
        <DatePicker
          id="organization-hub-form-activity-end-date"
          dateLabel={t("app_organizations_hub_activity_end_date")}
          onChangeDate={setDate}
          value={date}
          clearable
        />
      </MuiPickersUtilsProvider>}
    </PopUpConfirmation>
  )
}
