import { Box, makeStyles } from "@material-ui/core"
import {
  ConventionStatusValues,
  Organization,
  organizationClient,
  OrganizationStateMachine,
  OrganizationTypeValues
} from "api/organization"
import {
  Button, OrganizationFilters, OrganizationFiltersModel, OrganizationRow,
  OrganizationTable, PeriodPickerPopup, PopUpConfirmation, Section, StatusTags
} from "components"
import { DateInterval } from "components/src/Domain/BonusPayment"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { nullToUndefined } from "utils"
import { organizationTypeOptions } from "../../../../api/organization/utils"
import { getOrganizationLogo } from "../../../components/OrganizationDetailsForm/OrganizationLogo"
import PageWithTitle from "../../../components/PageWithTitle/PageWithTitle"
import { usePaginedList } from "../../../hooks/usePaginedList/usePaginedList"
import withConnect from "./withConnect"

const useStyles = makeStyles(() => ({
  button: {
    float: "right",
    marginRight: "30px",
    height: "49px !important",
  },
  topContainer: {
    display: "flex",
    justifyContent: "right",
    paddingRight: 30
  }
}));

interface deleteOrganization {
  confirmation: boolean;
  selectedOrganizationId?: string;
}

interface OrganizationListPageProps {
  gotoOrganizationsAdd: () => void;
  gotoOrganizationView: (organizationId: string) => void;
  gotoOrganizationsList: (
    page: number,
    size: number,
    organizationName?: string,
    organizationType?: string
  ) => void;
  setOrganizationToNull: () => void;
  deleteOrganization: (organizationId: string) => void;
  gotoOrganizationEditGeneralInformations: (organizationId: string) => void;
}

export const OrganizationListPage = (props: OrganizationListPageProps) => {
  const {
    gotoOrganizationsAdd,
    gotoOrganizationView,
    gotoOrganizationsList,
    setOrganizationToNull,
    deleteOrganization,
    gotoOrganizationEditGeneralInformations,
  } = props;
  const classes = useStyles();
  const authResult = new URLSearchParams(window.location.search);
  const [deleteConfirmation, openDeleteConfirmation] = useState<
    deleteOrganization
  >({ confirmation: false, selectedOrganizationId: undefined });
  const [openExportOrganizationPopup, setOpenExportOrganizationPopup] = useState<boolean>(false)
  const [filterValues, setFilterValues] = useState<OrganizationFiltersModel>({
    name: nullToUndefined(authResult.get("organizationName")),
    type: nullToUndefined(authResult.get("organizationType")),
  });

  const { t } = useTranslation();
  const fetchOrganizationsPage = useCallback(
    (page, size) =>
      organizationClient.query.fetchOrganizationsPage(
        page,
        size,
        filterValues.name,
        filterValues.type
      ),
    [filterValues.name, filterValues.type]
  );
  const gotoOrganizationsListCallback = useCallback(
    (page, size) =>
      gotoOrganizationsList(page, size, filterValues.name, filterValues.type),
    [gotoOrganizationsList, filterValues.name, filterValues.type]
  );
  const [items, isLoading, handlePageChange] = usePaginedList<Organization>(
    [],
    fetchOrganizationsPage,
    gotoOrganizationsListCallback,
    [fetchOrganizationsPage]
  );
  const onFilterChange = useCallback(
    (values: OrganizationFiltersModel) => {
      gotoOrganizationsList(0, items.perPage, values.name, values.type);
      setFilterValues(values);
    },
    [items.perPage, gotoOrganizationsList, setFilterValues]
  );
  const handleRowClickedCallback = useCallback(
    (row: OrganizationRow) => {
      setOrganizationToNull();
      gotoOrganizationView(row.id);
    },
    [gotoOrganizationView, setOrganizationToNull]
  );

  const getActions = useCallback(
    (row: Organization) => [
      {
        key: `see-${row.id}`,
        label: t("app_organization_action_see"),
        goto: () => {
          setOrganizationToNull();
          gotoOrganizationView(row.id);
        },
      },
      {
        key: `edit-${row.id}`,
        label: t("app_organization_action_update"),
        goto: () => {
          setOrganizationToNull();
          gotoOrganizationEditGeneralInformations(row.id);
        },
      },
      ...(OrganizationStateMachine.getAvailableActions(row.status.value)
        .canDelete
        ? [
            {
              key: `remove-${row.id}`,
              label: t("app_organization_action_remove"),
              goto: () =>
                openDeleteConfirmation({
                  confirmation: true,
                  selectedOrganizationId: row.id,
                }),
            },
          ]
        : []),
    ],
    [gotoOrganizationView, setOrganizationToNull]
  );

  const onConfirmDelete = useCallback(async () => {
    deleteConfirmation.selectedOrganizationId &&
      (await deleteOrganization(deleteConfirmation.selectedOrganizationId));
    items.list = items.list.filter(
      (org) => org.id !== deleteConfirmation.selectedOrganizationId
    );
    openDeleteConfirmation({
      confirmation: false,
      selectedOrganizationId: undefined,
    });
  }, [deleteConfirmation, deleteOrganization]);

  const getStatus = (org: Organization) => {
    if (!OrganizationTypeValues.isOperator(org.organizationType.type)) {
      return (
        <StatusTags
          label={t("app_organizations_status_active")}
          color="green"
        />
      );
    }
    if (org.convention?.status.value == ConventionStatusValues.FINALIZED.value) {
      return (
        <StatusTags
          label={t("app_organizations_status_active")}
          color="green"
        />
      );
    } else {
      return (
        <StatusTags
          label={t("app_organizations_status_signature_pending")}
          color="yellow"
        />
      );
    }
  };

  const onExportOrganizationButtonClick = useCallback(() => {
    setOpenExportOrganizationPopup(true)
  }, [])

  const closeExportOrganizationPopup = useCallback(() => {
    setOpenExportOrganizationPopup(false)
  }, [])

  const handleConfirmExportOrganizationPopup = useCallback(async (period: DateInterval) => {
    const objectUrl = await organizationClient.query.exportOrganizationsDolibarr(period, "Operator")
    if (objectUrl == null) {
      return;
    }
    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = `export.zip`;
    a.click();
    setOpenExportOrganizationPopup(false)
  }, [])

  return (
    <PageWithTitle
      headBar={{
        title: "Organisations",
      }}
      header={
        <>
          <OrganizationFilters
            values={filterValues}
            onValuesChange={onFilterChange}
            typeOptions={organizationTypeOptions(t)}
          />
          <Button className={classes.button} onClick={gotoOrganizationsAdd}>
            {t("app_organization_add_organization_button")}
          </Button>
        </>
      }
      columnSwitchWidth={900}
      switchedHeaderHeight={170}
    >
      <Box className={classes.topContainer}>
        <Button onClick={onExportOrganizationButtonClick}>
          {t("app_organization_export_organization_button")}
        </Button>
      </Box>
      <Section>
        <PeriodPickerPopup 
          open={openExportOrganizationPopup}
          title={t("app_organization_export_organization_popup_title")}
          body={t("app_organization_export_organization_popup_body")}
          onClose={closeExportOrganizationPopup}
          onConfirm={handleConfirmExportOrganizationPopup}
        />
        <PopUpConfirmation
          title={t("app_organization_action_delete_confirm")}
          onClose={() =>
            openDeleteConfirmation({
              confirmation: false,
              selectedOrganizationId: undefined,
            })
          }
          onConfirm={onConfirmDelete}
          open={deleteConfirmation.confirmation}
        />
        <OrganizationTable
          organizations={items.list}
          onRowClicked={handleRowClickedCallback}
          isLoading={isLoading}
          totalPages={Math.ceil(items.totalRows / items.perPage)}
          page={items.page + 1}
          handlePageChange={handlePageChange}
          getOrganizationImage={getOrganizationLogo}
          getActions={getActions}
          getStatus={getStatus}
        />
      </Section>
    </PageWithTitle>
  );
};

export default withConnect(OrganizationListPage);
