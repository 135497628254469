import { Box, Fab, Tooltip, makeStyles } from '@material-ui/core'
import React from 'react'
import { Hub } from 'web-admin/src/api/organization'
import { MenuItemAction, MoreOptions, PrimaryTypography, Table } from '../../..'
import { UTCToTimedZone } from '../../../utils'
import { Territory } from '../../GeoZone'
import { Comment } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  areasContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    align: 'left'
  },
  chip: {
    margin: 2,
    width: 'fit-content'
  },
  container: {
    '& .rdt_TableRow .rdt_TableCell:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(2n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(3n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableCol:nth-child(4n)': {
      minWidth: '120px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(5n)': {
      minWidth: '150px'
    },
    '& .rdt_TableCol:nth-child(5n)': {
      minWidth: '150px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableCol:nth-child(6n)': {
      minWidth: '140px'
    },
    '& .rdt_TableRow .rdt_TableCell:nth-child(7n)': {
      minWidth: '150px'
    },
    '& .rdt_TableCol:nth-child(7n)': {
      minWidth: '150px'
    },
    '& .rdt_TableRow .rdt_TableCell:last-child': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableCol:last-child': {
      minWidth: '80px',
      maxWidth: '80px'
    },
    '& .rdt_TableRow .rdt_TableCell:first-child': {
      minWidth: '200px'
    },
    '& .rdt_TableCol:first-child': {
      minWidth: '200px'
    }
  },
  fab: {
    width: 40,
    height: 40,
    backgroundColor: '#00000000',
    boxShadow: '0 0 black'
  },
  icon: {
    color: '#007DCE'
  },
  tooltipText: {
    fontSize:18,
    lineHeight: "normal",
    color: "white",
    overflowWrap: "normal"
  }
}))

export interface HubRow {
  id: string
  address: string
  activityStartDate: string
  activityEndDate: string
  territories: string
  comment: string
  actions: MenuItemAction[]
}

interface HubTableProps {
  hubs: Hub[]
  isLoading?: boolean
  getActions?: (hub: Hub) => MenuItemAction[]
}

export const HubTable = (props: HubTableProps) => {
  const {
    hubs,
    isLoading,
    getActions = () => []
  } = props

  const formatDate = (date: number): string => {
    return UTCToTimedZone(date).toLocaleDateString()
  }

  const classes = useStyles()

  const territoriesToString = (territories: Nullable<Territory[]>) => {
    const territoryNames = territories?.map((t) => t.name) ?? []
    return territoryNames.length > 0 ? territoryNames.join(", ") : "National (tous les territoires)"    
  }
  
  const data: HubRow[] = hubs.map(
    (hub): HubRow => {
      return {
        id: hub.id,
        address: `${hub.address.houseNumber} ${hub.address.street}, ${hub.address.zipCode} ${hub.address.city}`,
        activityStartDate: formatDate(hub.activityStartDate),
        activityEndDate: hub.activityEndDate ? formatDate(hub.activityEndDate) : "",
        territories:territoriesToString(hub.territories),
        comment: hub.comment,
        actions: getActions(hub)
      }
    }
  )

  const columns = [
    {
      name: 'Adresse',
      cell: (row: HubRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.address}
        </PrimaryTypography>
      )
    },
    {
      name: 'Date de début d\'activité',
      cell: (row: HubRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.activityStartDate}
        </PrimaryTypography>
      )
    },
    {
      name: 'Date de fin d\'activité',
      cell: (row: HubRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.activityEndDate}
        </PrimaryTypography>
      )
    },
    {
      name: 'Territoires',
      cell: (row: HubRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.territories}
        </PrimaryTypography>
      )
    },
    {
      name: 'Commentaire',
      cell: (row: HubRow) => (
        <PrimaryTypography
          data-tag='___react-data-table-allow-propagation___'
          align='left'
        >
          {row.comment !== "" && <Tooltip 
          title={<p className={classes.tooltipText}>{row.comment}</p>}
           arrow>
            <Fab className={classes.fab}>
              <Comment className={classes.icon} />
            </Fab>
          </Tooltip>}
        </PrimaryTypography>
      )
    },
    {
      name: 'Action',
      cell: (row: HubRow) => {
        const actions = row.actions.filter(action => {
          if (action.key == "deactivate" && row.activityEndDate.length > 0){
            return false
          }
          return true
        })
        return (
          <Box>
            <MoreOptions
              handleClose={() => {}}
              options={!!actions ? actions : []}
            />
          </Box>
        )
      }
    }
  ]
  return (
    <Table
      data={data}
      columns={columns}
      className={classes.container}
      isLoading={isLoading}
      keyField='id'
      noDataMessage={""}
    />
  )
}
