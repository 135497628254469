import { Address } from ".."

export interface AddressGetResponse {
  type: string
  version: string
  features: AddressFeature[]
  attribution: string
  licence: string
  query: string
  filters: any
  limit: number
}

export interface AddressFeature {
  type: string
  geometry: AddressFeatureGeometry
  properties: AddressFeatureProperties
}

export interface AddressFeatureGeometry {
  coordinates: number[]
  type: string
}

export interface AddressFeatureProperties {
  score: number
  label: string
  housenumber: string
  id: string
  name: string
  postcode: string
  citycode: string
  x: number
  y: number
  city: string
  district: string
  context: string
  type: string
  importance: number
  street: string
}

export interface GeoApiAddressGetQueryParams {
  address: string,
}

export const fetchGeoApiAddress = async (params: GeoApiAddressGetQueryParams): Promise<Address[]> => {
  try {
    const resp = await fetch(
      `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(params.address)}&type=housenumber&autocomplete=1&limit=15`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data: AddressGetResponse = await resp.json();

    return data.features.sort((a, b) => a.properties.score - b.properties.score)
      .reverse()
      .map(value => {
        const address: Address = {
          zipCode: value.properties.citycode,
          city: value.properties.city,
          street: value.properties.street,
          houseNumber: value.properties.housenumber,
          geoLocation: {
            lon: value.geometry.coordinates[0],
            lat: value.geometry.coordinates[1],
          }
        }
        return address
      })
  } catch (err) {
    console.log(err);
  }
  return [];
}
